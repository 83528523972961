@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,800;1,300;1,400;1,500;1,800&display=swap");
.gradient-light-top, body {
  background: #fff;
  background: -moz-linear-gradient(180deg, #EDF5FC 0%, #fff 50%);
  background: -webkit-linear-gradient(180deg, #EDF5FC 0%, #fff 50%);
  background: linear-gradient(180deg, #EDF5FC 0%, #fff 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$brand-white",endColorstr="$white",GradientType=1);
  background-repeat: no-repeat;
  background-size: cover; }

.gradient-light-bottom {
  background: #fff;
  background: -moz-linear-gradient(180deg, #fff 0%, #EDF5FC 50%);
  background: -webkit-linear-gradient(180deg, #fff 0%, #EDF5FC 50%);
  background: linear-gradient(180deg, #fff 0%, #EDF5FC 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white",endColorstr="$brand-white",GradientType=1);
  background-repeat: no-repeat;
  background-size: cover; }

.gradient-dark, .hero {
  background: #1C355E;
  background: -moz-linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
  background: -webkit-linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
  background: linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$headline",endColorstr="$primary",GradientType=1); }

body {
  font-family: 'Montserrat', sans-serif;
  background-size: 100% 50vh; }
  body.modal-open {
    padding-right: 0 !important; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.kc-main h1,
.h4,
.h5,
.h6 {
  color: #1C355E;
  font-weight: 800; }

h1 small {
  font-size: 1.25rem;
  font-weight: 500;
  color: #0067B9;
  text-transform: uppercase;
  display: block; }

h2 {
  font-size: 2.75rem;
  margin-block-end: 1.25rem; }
  h2:not(:first-child) {
    margin-block-start: 2.25rem; }
  h2 small {
    font-size: 1.25rem;
    font-weight: 500;
    color: #0067B9;
    text-transform: uppercase;
    display: block; }

h3,
.h3,
.kc-main h1 {
  font-size: 2rem; }
  h3:not(:first-child),
  .h3:not(:first-child),
  .kc-main h1:not(:first-child) {
    margin-block-start: 1.5rem; }

h4 {
  color: #0067B9; }

h5 {
  margin-top: 1rem; }

a {
  color: #0067B9; }

p,
main li {
  font-size: 1.125rem;
  margin: 1.125rem 0 1.5rem 0; }
  p.lg,
  main li.lg {
    font-size: 2rem;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased; }
  p.lg-alt,
  main li.lg-alt {
    font-size: 1.5rem; }
  p.sm,
  main li.sm {
    font-size: 0.875rem; }

.nowrap {
  white-space: nowrap; }

ol li {
  margin: 1em 0; }

ul li {
  padding-inline-start: 0.5rem; }

.content-section {
  padding: 3.75rem 0; }

img {
  max-width: 100%; }

.primary {
  color: #0067B9; }

.photo {
  border-radius: 2rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 38, 62, 0.1), 2rem 3rem 0 #EDF5FC;
  max-width: 100%; }

.white {
  color: #fff; }

.bg-light {
  background: #EDF5FC; }

.ss-shadow {
  border-radius: 1rem;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1); }

.icon-left {
  display: flex; }
  .icon-left .icon-container {
    flex: 0 0 auto;
    width: 80px; }
  .icon-left .icon-content {
    flex: 1 1 auto;
    padding-left: 1rem; }

@media screen and (max-width: 767px) {
  p {
    font-size: 1rem;
    margin: 1rem 0 1rem 0; }
    p.lg {
      font-size: 1.35rem;
      line-height: 1.2; }
  h2 {
    font-size: 2rem; }
  .icon-left {
    flex-direction: column;
    align-items: center; }
    .icon-left .icon-container {
      margin-bottom: 1rem; }
    .icon-left .icon-content .btn {
      display: block; } }

.no-col .container {
  padding: 0 !important; }
  .no-col .container .row {
    display: block !important;
    margin: 0 !important; }
    .no-col .container .row > * {
      padding: 0 !important;
      width: auto !important;
      margin: 2rem 0 !important; }

footer {
  border-top: 5px solid #0067B9; }

.footer-sri-super-header {
  line-height: 1em;
  font-size: 12px;
  color: #666666;
  font-family: 'Proxima Nova', 'Helvetica Neue', 'Arial', sans-serif; }

.footer-sri-header {
  line-height: 1em;
  font-size: 20px;
  color: #666666;
  font-weight: 600;
  font-family: 'Proxima Nova', 'Helvetica Neue', 'Arial', sans-serif;
  margin-top: 0.15em; }

#header {
  position: relative;
  z-index: 100;
  margin-bottom: -100px; }

.prime-nav {
  height: 100px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 !important; }
  .prime-nav .navbar-collapse {
    justify-content: space-between; }
  .prime-nav .nav-link {
    margin-left: 1rem;
    color: #0067B9 !important; }
  .prime-nav .nav-link {
    font-size: 1.125rem;
    text-decoration: none;
    align-items: center;
    display: inline-flex; }
    .prime-nav .nav-link .svg-inline--fa {
      height: 1rem;
      width: 1rem;
      margin-left: 0.75rem; }
  .prime-nav .header-login {
    background: rgba(0, 103, 185, 0.15);
    color: #0067B9;
    border-color: transparent; }
    .prime-nav .header-login:hover {
      color: #fff; }

.navbar-light .navbar-toggler {
  border-color: transparent;
  margin-right: 0 !important; }
  .navbar-light .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.364' height='16' viewBox='0 0 20.364 16'%3E%3Cpath id='bars' d='M0,64.727A.727.727,0,0,1,.727,64H19.636a.727.727,0,1,1,0,1.455H.727A.727.727,0,0,1,0,64.727ZM0,72a.728.728,0,0,1,.727-.727H19.636a.727.727,0,1,1,0,1.455H.727A.728.728,0,0,1,0,72Zm19.636,8H.727a.727.727,0,1,1,0-1.455H19.636a.727.727,0,1,1,0,1.455Z' transform='translate(0 -64)' fill='%230067b9'/%3E%3C/svg%3E%0A"); }

#header.knockout .prime-nav .nav-link {
  color: #fff !important; }

#header.knockout .prime-nav .header-login {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
  color: #fff !important; }

#header.knockout .navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.364' height='16' viewBox='0 0 20.364 16'%3E%3Cpath id='bars' d='M0,64.727A.727.727,0,0,1,.727,64H19.636a.727.727,0,1,1,0,1.455H.727A.727.727,0,0,1,0,64.727ZM0,72a.728.728,0,0,1,.727-.727H19.636a.727.727,0,1,1,0,1.455H.727A.728.728,0,0,1,0,72Zm19.636,8H.727a.727.727,0,1,1,0-1.455H19.636a.727.727,0,1,1,0,1.455Z' transform='translate(0 -64)' fill='%23fff'/%3E%3C/svg%3E%0A"); }

.offcanvas {
  height: auto;
  bottom: auto; }
  .offcanvas-body .links [class^='col'] {
    margin-bottom: 3rem; }
  .offcanvas-body .title {
    font-size: 1.875rem;
    font-weight: 800;
    color: #1C355E;
    border-bottom: 1px solid rgba(0, 103, 185, 0.18);
    margin-bottom: 1em;
    padding-bottom: 0.5rem; }
  .offcanvas .nav-link-block {
    display: flex;
    text-decoration: none; }
    .offcanvas .nav-link-block-icon {
      width: 3.5rem;
      flex: 0 0 auto;
      color: rgba(0, 103, 185, 0.6); }
    .offcanvas .nav-link-block-content {
      flex: 1 1 auto; }
    .offcanvas .nav-link-block-heading {
      font-weight: 800;
      color: #0067B9;
      font-size: 1.25rem;
      margin-bottom: 0.5rem; }
    .offcanvas .nav-link-block-copy {
      color: #00263E; }
  .offcanvas .toggle-back {
    white-space: nowrap; }
  .offcanvas-backdrop {
    background: rgba(0, 0, 0, 0.2); }
  .offcanvas-backdrop.show {
    opacity: 1;
    backdrop-filter: blur(5px); }

@media screen and (max-width: 767px) {
  .prime-nav .navbar-collapse.collapse,
  .prime-nav .navbar-collapse {
    position: absolute !important;
    top: 100px !important;
    left: 0;
    right: 0;
    height: calc(100vh - 100px) !important;
    transition: none !important;
    padding: 1.5rem;
    background: #fff;
    background: -moz-linear-gradient(180deg, #EDF5FC 0%, #fff 50%);
    background: -webkit-linear-gradient(180deg, #EDF5FC 0%, #fff 50%);
    background: linear-gradient(180deg, #EDF5FC 0%, #fff 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$brand-white",endColorstr="$white",GradientType=1);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center top; }
    .prime-nav .navbar-collapse.collapse .nav-link,
    .prime-nav .navbar-collapse .nav-link {
      margin-bottom: 1rem; }
  .prime-nav .navbar-brand,
  .prime-nav .navbar-brand svg {
    max-width: 170px; }
  #header.knockout .prime-nav .navbar-collapse.collapse,
  #header.knockout .prime-nav .navbar-collapse {
    background: #1C355E;
    background: -moz-linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
    background: -webkit-linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
    background: linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$headline",endColorstr="$primary",GradientType=1);
    background-attachment: fixed; }
    #header.knockout .prime-nav .navbar-collapse.collapse .navbar-brand,
    #header.knockout .prime-nav .navbar-collapse.collapse .navbar-brand svg,
    #header.knockout .prime-nav .navbar-collapse .navbar-brand,
    #header.knockout .prime-nav .navbar-collapse .navbar-brand svg {
      max-width: 170px; }
  #header.knockout .prime-nav .navbar-collapse.collapse,
  #header.knockout .prime-nav .navbar-collapse {
    background: #1C355E;
    background: -moz-linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
    background: -webkit-linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
    background: linear-gradient(180deg, #1C355E 0%, #0067B9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$headline",endColorstr="$primary",GradientType=1);
    background-attachment: fixed; }
  #header.knockout .prime-nav .navbar-brand,
  #header.knockout .prime-nav .navbar-brand svg {
    max-width: 170px; }
  .offcanvas .navbar-toggler {
    display: none; }
  .offcanvas-body, .offcanvas-header {
    padding: 0; } }

.btn {
  border-radius: 100px;
  padding: .625rem 1.5rem; }
  .btn-lg {
    font-size: 1.5rem; }
  .btn-text {
    color: #0067B9; }
  .btn-primary {
    background: #0067B9;
    border-color: #0067B9; }
    .btn-primary:hover {
      background: #5182ED;
      border-color: #5182ED; }
  .btn-outline-light {
    border-color: #5182ED;
    background: #5182ED; }
    .btn-outline-light:hover {
      background-color: #5182ED;
      border-color: #5182ED;
      color: #fff; }

@media screen and (max-width: 767px) {
  .btn-lg {
    font-size: 1.25rem; } }

.hero,
.masthead {
  padding-top: 100px; }
  .hero h1,
  .masthead h1 {
    font-weight: 800;
    font-size: 4rem;
    line-height: 1.1;
    margin-bottom: 2rem; }
    @media screen and (max-width: 767px) {
      .hero h1,
      .masthead h1 {
        font-size: 2.5rem; } }

.masthead {
  padding-bottom: 40px;
  padding-top: 100px; }

.hero {
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center; }
  .hero-interior {
    background: url(../images/logo-background.svg) center 60px no-repeat;
    overflow: hidden;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .hero .screenshot {
    background-size: contain;
    display: flex;
    justify-content: stretch;
    flex: 1 1 auto; }
    .hero .screenshot img {
      max-width: 1400px;
      width: 100%;
      object-fit: contain;
      object-position: center bottom; }
  .hero .content {
    flex: 2 1 auto;
    display: flex;
    align-items: center; }
    .hero .content h1 {
      color: #fff; }
    .hero .content .btn {
      background: #5182ED;
      border-color: #5182ED; }

@media screen and (max-width: 767px) {
  .masthead {
    padding-bottom: 1rem;
    padding-top: 75px; }
  .hero .content {
    flex: 1 0 auto; }
  .hero .screenshot {
    flex: 1 1 auto; } }

.accordion {
  margin: 2rem 0; }
  .accordion-header {
    margin: 0; }
  .accordion-item {
    border-color: rgba(0, 103, 185, 0.2); }
  .accordion-button {
    font-weight: 800;
    color: #1C355E;
    font-size: 1.25rem;
    padding-left: 0;
    padding-right: 0; }
    .accordion-button:not(.collapsed) {
      box-shadow: none;
      background: none;
      color: #1C355E; }
  .accordion-icon {
    width: 2.5rem;
    font-size: 2rem;
    text-align: center;
    margin-right: 1rem; }
  .accordion-body {
    padding-left: 3.5rem;
    padding-top: 0;
    padding-bottom: 1.5rem; }

ul.nav.nav-tabs {
  justify-content: center;
  border-bottom: none; }
  ul.nav.nav-tabs .nav-link {
    padding: 0.5rem 0.5rem;
    font-weight: 800;
    font-size: 1.125rem;
    color: #0067B9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 0 1.5rem; }
    ul.nav.nav-tabs .nav-link .svg-inline--fa {
      margin-bottom: 0.75rem; }
    ul.nav.nav-tabs .nav-link:hover {
      border-color: transparent;
      color: #5182ED !important; }
    ul.nav.nav-tabs .nav-link.active {
      border-color: transparent;
      box-shadow: 0 2px 0 #0067B9;
      color: #0067B9;
      background: none; }

.tab-pane {
  padding-top: 3rem; }

.counter {
  text-align: center;
  cursor: pointer; }
  .counter-value {
    color: #1C355E;
    font-size: 4rem;
    font-weight: 800; }
  .counter-label {
    color: #0067B9; }
  .counter-sublabel {
    font-size: 0.75rem; }
  .counter .cite-link {
    display: inline-block;
    border-bottom: 1px dotted #0067B9;
    vertical-align: super;
    font-weight: normal;
    padding: 0 1px;
    line-height: 0.35;
    margin-left: 2px; }

@media screen and (max-width: 767px) {
  .counter-value {
    font-size: 3rem; }
  .counter-sublabel {
    font-size: 0.9rem; } }

.list-checkmarks {
  font-size: .875rem;
  list-style: none; }
  .list-checkmarks li {
    margin: 1.125rem 0; }
    .list-checkmarks li:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'%3E%3Cpath id='circle-check' d='M10.111,14.236a.682.682,0,0,1-.971,0l-2.75-2.75a.687.687,0,0,1,.971-.971l2.264,2.264,5.014-5.014a.687.687,0,0,1,.971.971ZM22,11A11,11,0,1,1,11,0,11,11,0,0,1,22,11ZM11,1.375A9.625,9.625,0,1,0,20.625,11,9.626,9.626,0,0,0,11,1.375Z' fill='%230067b9'/%3E%3C/svg%3E");
      position: absolute;
      transform: translateX(-2.5em); }

ul li {
  margin: 1rem 0; }

.form-control, .form-select {
  border-radius: .75rem;
  border-color: transparent; }
  .form-control:focus, .form-select:focus {
    border-color: transparent;
    box-shadow: 0 0 0 0.25rem rgba(0, 103, 185, 0.25); }

.gradient-dark .form-control:focus, .hero .form-control:focus, .gradient-dark .form-select:focus, .hero .form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 245, 252, 0.25); }

.on-white .form-control, .on-white .form-select {
  border-color: #d6d6d6;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.05); }
  .on-white .form-control:focus, .on-white .form-select:focus {
    border-color: transparent;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.05), 0 0 0 0.25rem rgba(0, 103, 185, 0.25); }

footer {
  padding-bottom: 3rem; }
  footer a {
    text-decoration: none; }
    footer a:hover {
      text-decoration: underline;
      color: #5182ED; }
  footer .legal {
    font-size: .75rem;
    color: #0067B9;
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 103, 185, 0.2);
    display: flex;
    justify-content: space-between; }
    footer .legal a {
      display: inline-block;
      margin-left: 1rem;
      text-decoration: underline; }
    footer .legal .fda {
      color: #00263E; }
  footer .footer-links {
    text-align: right; }
    footer .footer-links a {
      display: inline-block;
      margin-left: 3rem; }
  footer .footer-osu img {
    max-width: 300px; }
  footer .footer-osu p {
    font-size: .75rem; }

@media screen and (max-width: 767px) {
  footer .footer-links a {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    margin-left: 0; }
  footer .footer-logo {
    text-align: center; } }

.changelog-version {
  margin-top: 8rem; }
  .changelog-version:first-child {
    margin-top: 0; }
  .changelog-version h2 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 103, 185, 0.2);
    font-size: 2rem;
    align-items: flex-end;
    padding-bottom: 1rem; }
    .changelog-version h2 span {
      font-size: 1rem;
      font-weight: 400; }
  .changelog-version h3 {
    margin-bottom: 1rem;
    margin-top: 2rem; }

.smart-hider {
  width: inherit; }
  .smart-hider.hide-when {
    display: inherit !important; }
  .smart-hider.show-when {
    display: none !important; }

@media (max-width: 575.98px) {
  .smart-hider.hide-when.xs {
    display: none !important; }
  .smart-hider.show-when.xs {
    display: inherit !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .smart-hider.hide-when.sm {
    display: none !important; }
  .smart-hider.show-when.sm {
    display: inherit !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .smart-hider.hide-when.md {
    display: none !important; }
  .smart-hider.show-when.md {
    display: inherit !important; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .smart-hider.hide-when.lg {
    display: none !important; }
  .smart-hider.show-when.lg {
    display: inherit !important; } }

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .smart-hider.hide-when.xl {
    display: none !important; }
  .smart-hider.show-when.xl {
    display: inherit !important; } }

@media (min-width: 1400px) {
  .smart-hider.hide-when.xxl {
    display: none !important; }
  .smart-hider.show-when.xxl {
    display: inherit !important; } }

.smart-hider.hide-when.xsUp {
  display: none !important; }

.smart-hider.show-when.xsUp {
  display: inherit !important; }

@media (min-width: 576px) {
  .smart-hider.hide-when.smUp {
    display: none !important; }
  .smart-hider.show-when.smUp {
    display: inherit !important; } }

@media (min-width: 768px) {
  .smart-hider.hide-when.mdUp {
    display: none !important; }
  .smart-hider.show-when.mdUp {
    display: inherit !important; } }

@media (min-width: 992px) {
  .smart-hider.hide-when.lgUp {
    display: none !important; }
  .smart-hider.show-when.lgUp {
    display: inherit !important; } }

@media (min-width: 1200px) {
  .smart-hider.hide-when.xlUp {
    display: none !important; }
  .smart-hider.show-when.xlUp {
    display: inherit !important; } }

@media (min-width: 1400px) {
  .smart-hider.hide-when.xxlUp {
    display: none !important; }
  .smart-hider.show-when.xxlUp {
    display: inherit !important; } }

.smart-hider.hide-when.xsDown {
  display: none !important; }

.smart-hider.show-when.xsDown {
  display: inherit !important; }

@media (max-width: 575.98px) {
  .smart-hider.hide-when.smDown {
    display: none !important; }
  .smart-hider.show-when.smDown {
    display: inherit !important; } }

@media (max-width: 767.98px) {
  .smart-hider.hide-when.mdDown {
    display: none !important; }
  .smart-hider.show-when.mdDown {
    display: inherit !important; } }

@media (max-width: 991.98px) {
  .smart-hider.hide-when.lgDown {
    display: none !important; }
  .smart-hider.show-when.lgDown {
    display: inherit !important; } }

@media (max-width: 1199.98px) {
  .smart-hider.hide-when.xlDown {
    display: none !important; }
  .smart-hider.show-when.xlDown {
    display: inherit !important; } }

@media (max-width: 1399.98px) {
  .smart-hider.hide-when.xxlDown {
    display: none !important; }
  .smart-hider.show-when.xxlDown {
    display: inherit !important; } }

.kc-layout {
  display: flex;
  align-items: flex-start; }

.kc-sidebar {
  width: 375px;
  padding-right: 2rem;
  border-right: 1px solid rgba(0, 103, 185, 0.2);
  flex: 1 0 auto;
  position: sticky;
  top: 0;
  max-height: 100vh;
  padding-bottom: 500px;
  overflow-y: auto; }
  .kc-sidebar-mobile-container {
    margin-bottom: 2rem; }
    .kc-sidebar-mobile-container > .container {
      background: #5182ED; }
    .kc-sidebar-mobile-container button.kc-mobile-toggle {
      width: 100%;
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .kc-sidebar-nav-mobile {
    width: 90%;
    bottom: 0; }
    .kc-sidebar-nav-mobile .offcanvas-header {
      padding: 3rem 1.5rem 2rem 1.5rem; }
      .kc-sidebar-nav-mobile .offcanvas-header .h5 {
        font-size: 1.5rem; }
    .kc-sidebar-nav-mobile .offcanvas-body {
      padding: 0 1.5rem 1.5rem 1.5rem; }

.kc-links-group, .kc-links-group-links {
  list-style: none;
  margin: 0;
  padding: 0; }

.kc-links-group strong {
  color: #1C355E;
  font-size: 1.15rem; }
  .kc-links-group strong svg {
    width: 1.6rem; }

.kc-links-group-links {
  padding-left: 2.2rem; }
  .kc-links-group-links li {
    margin: 0.5rem 0; }
  .kc-links-group-links button {
    text-align: left;
    text-decoration: none;
    padding: 0.5rem;
    margin: 0;
    margin-left: -0.5rem;
    border: none;
    background: none;
    color: #0067B9;
    display: block;
    text-align: left;
    width: 100%; }
    .kc-links-group-links button:hover {
      text-decoration: underline; }
    .kc-links-group-links button.current {
      font-weight: bold;
      background: #EDF5FC;
      border-radius: 0.25rem; }

.kc-main {
  min-width: 0;
  flex: 1 1 auto; }
  @media screen and (min-width: 992px) {
    .kc-main {
      padding-left: 2rem; } }
  .kc-main h2 {
    font-size: 1.75rem; }
  .kc-main h3 {
    font-size: 1.5rem; }
  .kc-main figure.figure {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .kc-main figure.figure .figure-img {
      flex: 1 0 auto;
      display: block;
      object-fit: contain; }
    .kc-main figure.figure .figure-caption {
      flex: 0 1 auto;
      text-align: center;
      font-size: 1.25rem; }
  .kc-main .warning {
    display: flex; }
    .kc-main .warning:before {
      display: block;
      width: 70px;
      height: 70px;
      background: url(../images/warning.png) center top no-repeat;
      background-size: contain;
      content: "";
      flex: 1 0 auto; }
    .kc-main .warning p {
      margin-top: 0; }
    .kc-main .warning-right {
      flex-direction: row-reverse; }
      .kc-main .warning-right:before {
        margin-left: 1em; }
    .kc-main .warning-left:before {
      margin-right: 1em; }

.kc-article-title {
  font-weight: bold;
  color: #1C355E; }
  .kc-article-title + h2 {
    margin-top: 0; }
